import "./App";
import { Application } from "@hotwired/stimulus";
import "@hotwired/turbo-rails";
import "../confirm";
import { registerControllers } from "../registerControllers";

document.addEventListener("turbo:submit-start", function () {
  document.querySelector("html").ariaBusy = true;
});

document.addEventListener("turbo:submit-end", function () {
  document.querySelector("html").ariaBusy = false;
});

// stimulus
const application = Application.start();
application.warnings = false;
application.debug = false;

import VideoController from "../controllers/video_controller";
application.register("video", VideoController);

import TrackingController from "../controllers/tracking_controller";
application.register("tracking", TrackingController);

import VisibilityController from "../controllers/visibility_controller";
application.register("visibility", VisibilityController);

registerControllers(
  application,
  import.meta.glob(
    "../../../app/components/legacy_ui/**/*_controller.{js,ts}",
    { eager: true },
  ),
);

registerControllers(
  application,
  import.meta.glob("../../../app/components/salon/**/*_controller.{js,ts}", {
    eager: true,
  }),
);
registerControllers(
  application,
  import.meta.glob("../../../app/components/hairbook/**/*_controller.{js,ts}"),
);
